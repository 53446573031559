

function App() {
  return (
    <>
      <h1>Funcionando!</h1>
    </>
  );
}

export default App;
